<template>
<div>
  <div class="login">
    <div class="login-con">
      <Card icon="log-in" title="注册用户" :bordered="false">
        <div class="form-con">
          <Form :model="formLogin" ref="formLogin" label-width="80">
            <FormItem label="用户名">
              <Input v-model="formLogin.username" placeholder="请输入用户名"></Input>
            </FormItem>
            <FormItem label="密码">
              <Input v-model="formLogin.password" placeholder="请输入密码" type="password"></Input>
            </FormItem>
            <FormItem label="验证码">
              <Row :gutter="20">
                <Col span="16">
                  <Input v-model="formLogin.captcha" placeholder="请输入验证码"></Input>
                </Col>
                <Col span="8">
                  <img class="captcha" :src="captchaUrl" alt="验证码" @click="refreshCaptcha()">
                </Col>
              </Row>
            </FormItem>
          </Form>
          <Button size="large" style="width: 100%" type="primary" @click="submit">注册</Button>
        </div>
        <div style="margin-top: 20px;text-align: right">
          <router-link :to="{path:'/'}">已有账号？登录</router-link>
        </div>
      </Card>
    </div>
  </div>
</div>
</template>

<script>
export default {
data(){
  return{
    showModal: false,
    activeTabName: "login",
    formLogin: {
      username: "",
      password: "",
      captcha: ""
    },
    captchaUrl: "",
    formRegister: {
      username: "",
      password: "",
      confirmPassword: "",
      phone: ""
    },
    formSmsLogin: {
      phone: "",
      smsCode: "",
      timer: null,
      countDown: 60
    }
   }
 },
  methods:{
    refreshCaptcha() {
      this.captchaUrl = "/api/captcha?" + Date.now();
    },
  }
}
</script>

<style scoped lang="less">
.login{
  width: 100%;
  height: calc(100vh);
  background-size: cover;
  background-position: center;
  position: relative;
  &-con{
    position: absolute;
    right: 160px;
    top: 50%;
    transform: translateY(-60%);
    width: 450px;
    &-header{
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      padding: 30px 0;
    }
    .form-con{
      padding: 10px 0 0;
    }
    .login-tip{
      font-size: 10px;
      text-align: center;
      color: #c3c3c3;
    }
  }
}
</style>
