<template>
   <div class="container">
      <!-- 添加15个音频标签 定义css变量，表示延迟时间,一共是15个标签，中间位置为8，所以设置
    第八个位置延迟为0-->
      <!-- <span style="--d: 7"></span>
      <span style="--d: 6"></span> -->
      <span style="--d: 5"></span>
      <span style="--d: 4"></span>
      <span style="--d: 3"></span>
      <span style="--d: 2"></span>
      <span style="--d: 1"></span>
      <span style="--d: 0"></span>
      <span style="--d: 1"></span>
      <span style="--d: 2"></span>
      <span style="--d: 3"></span>
      <span style="--d: 4"></span>
      <span style="--d: 5"></span>
      <!-- <span style="--d: 6"></span>
      <span style="--d: 7"></span> -->
    </div>
</template>

<script>
export default {

}
</script>

<style>
    /* 定义全局变量，音频的高度 */
    :root {
      --h: 20px;
    }
    .container {
      display: flex;
      align-items: center;
      position: relative;
      height: var(--h);
    }
    .container span {
      width: 2px;
      height: 10%;
      border-radius: calc(var(--h) * 0.2 * 0.5);
      margin-right: 2px;
      background: linear-gradient(to top, #0b2bcd 0%, #5b18cf 100%);
      /* 添加动画效果 */
      animation: loading 1s infinite linear;
      /* 添加动画延迟，实现此起彼伏的效果 */
      animation-delay: calc(0.2s * var(--d));
    }
    .container span:last-child {
      margin-right: 0;
      /* 最后一个标签不需要右边距 */
    }
    @keyframes loading {
      0% {
        height: 20%;
        border-radius: calc(var(--h) * 0.2 * 0.5);
        background: linear-gradient(to right, #0a709a 0%, #010c6c 100%);
      }
      50% {
        height: 100%;
        border-radius: calc(var(--h) * 1 * 0.5);
        background: linear-gradient(to top, #1d03c6 0%, #0056cf 100%);
      }
      100% {
        height: 20%;
        border-radius: calc(var(--h) * 0.2 * 0.5);
        background: linear-gradient(to top, #211e76 0%, #1c1c8f 100%);
      }
    }
</style>