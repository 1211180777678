<template>
  <div>
    <div class="feedback">
      <Modal
        class-name="feed_modal"
        v-model="showModal"
        :mask-closable="false"
        width="460"
      >
        <Form
          :model="formFeed"
          ref="formFeed"
          :rules="rulesValidate"
          label-position="top"
        >
          <div class="suggestionLabel">请输入产品功能问题及改进建议</div>
          <FormItem prop="suggestion">
            <Input
              v-model="formFeed.suggestion"
              :rows="4"
              type="textarea"
              clearable
              placeholder="请输入您的意见/建议（200字以内）"
            ></Input>
          </FormItem>
          <div class="uploadPic">
            <Upload action="" :before-upload="handleUpload">
              <Button icon="ios-cloud-upload-outline">上传图片</Button>
            </Upload>
          </div>
          <p class="tip">支持上传1张图片，格式为jpg或png</p>
          <p class="moreTips">
            更多问题/建议可通过邮箱反馈：feedback@ciigpt.com
          </p>
        </Form>

        <div class="login_footer" slot="footer">
          <Button
            class="loginButton"
            style="width: 20%"
            type="info"
            @click="cancelFeed"
          >
            取消
          </Button>
          <Button
            class="loginButton"
            :loading="loadingBtn"
            style="width: 20%"
            type="primary"
            @click="sumFeed"
          >
            提交
          </Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      file: null,
      formFeed: {
        suggestion: "",
      },
      rulesValidate: {
        suggestion: {
          required: true,
          trigger: "blur",
          message: "请输入您反馈的问题以及建议",
        },
      },
      loadingBtn: false,
    };
  },
  methods: {
    cancelFeed() {
      this.showModal = false;
      this.file = null;
      this.formFeed.suggestion = null;
      this.loadingBtn = false;
    },
    handleUpload(file) {
      // 在这儿控制文件数量
      console.log(file, "只要最新提交的文件");
      this.file = file;
      return false;
    },
    upload() {
      this.loadingStatus = true;
      setTimeout(() => {
        this.file = null;
        this.loadingStatus = false;
        this.$Message.success("Success");
      }, 1500);
    },
    // 提交反馈数据
    sumFeed() {
        this.loadingBtn = true;
    },
  },
  mounted() {},
};
</script>

<style>
.suggestionLabel {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.uploadPic {
  background-color: #f2f4fa;
  height: 70px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}
.tip{
    color: #999;
}
.moreTips {
  width: 100%;
  color: #999;
  /* text-align: right; */
}
</style>
