<template>
  <div>
  
    <input type="file" id="file1" accept="*" @change="file1Onchange()" />
    <div id="result"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    file1Onchange(){
      var file = file1.files[0];
        //读取为二进制
        var reader = new FileReader();
        reader.readAsText(file, "utf-8");
        reader.onload = function () {
          var str = reader.result;
          var rows = str.split("\n");
          var clients = [];
          for (var i = 0; i < rows.length; i++) {
            var row = rows[i].split(",");
            var client = {
              id: row[0],
              company: row[1],
              address: row[2],
              name: row[3],
              phone: row[4],
              coord: { x: row[5], y: row[6] },
            };
            clients.push(client);
          }
          //由对象转为JSON字符串
          console.log(JSON.stringify(clients));
          document.getElementById("result").innerHTML = JSON.stringify(clients);
          console.log(rows);
          document.getElementById("result").innerHTML = rows;
        };
    },
  },
  mounted(){
      // console.log(csvfile)
  },  
};
</script>

<style>
/* #file1 {
  position: absolute;
  right: 0px;
  opacity: 0;
} */
</style>
